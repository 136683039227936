import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import RichText from './RichText'
import { useInView } from 'react-intersection-observer'
import Button from '~components/Button'
import { Play, Rarr } from './Svg'
import useBreakpoint from '~utils/useBreakpoint'

const ScrollJack = ({ className, content }) => {
	const {isMobile} = useBreakpoint()

	const [currentScent, setCurrentScent] = useState(0)

	const [ref0, inView0] = useInView({threshold: 1,})
	const [ref1, inView1] = useInView({threshold: 1,})
	const [ref2, inView2] = useInView({threshold: 1,})
	const [ref3, inView3] = useInView({threshold: 1,})
	const [ref4, inView4] = useInView({threshold: 1,})

	useEffect(() => {
		if (inView0) {
			setCurrentScent(0)
		}
		if (inView1) {
			setCurrentScent(1)
		}
		if (inView2) {
			setCurrentScent(2)
		}
		if (inView3) {
			setCurrentScent(2)
		}
		if (inView4) {
			setCurrentScent(2)
		}
	}, [ inView0, inView1, inView2])

	const returnRef = index => {
		if (index === 0){
			return ref0
		}
		if (index === 1){
			return ref1
		}
		if (index === 2){
			return ref2
		}
		if (index === 3){
			return ref3
		}
		if (index === 4){
			return ref4
		}
	}

	return (
		<Wrap className={className}>
			<Images>
				<StickyContainer>
					{content.scents.map((scent, i) => (
						<StickyImage 
							key={scent.title}
							image={scent.image} cover aspectRatio={isMobile ? 1 : null}
							css={css`
								opacity: ${currentScent === i ? '1' : '0'};
								transition: opacity 0.3s;
								transition-delay: ${currentScent === i ? '0s' : '0.3s'};
							`}
						/>
					))}
				</StickyContainer>
			</Images>
			<ContentRight>
				<StickyContainerRight>
					<StickyTitle>{content.title}</StickyTitle>
					<Explore>
						<Button className='h3' link={content?.button?.link}>{content?.button?.text}</Button>
					</Explore>
				</StickyContainerRight>
			</ContentRight>
			<Content>
				<Inner>
					<Title>{content.title}</Title>
					<Text><RichText content={content.text}/></Text>
					<Scroll className='h3'>Scroll for more <Darr /></Scroll>
					{content.scents?.map((scent, i) => 
						<Scent key={scent.key} ref={returnRef(i)}>
							<ScentTitle className='h1'>
								<Number dangerouslySetInnerHTML={{__html: `&#931${i + 2}`}}/> 
								{scent.title}
							</ScentTitle>
							<Notes>
								{scent.fragranceNotes.map(note => (
									<h2 className='h1' key={note}>{note}</h2>
								))}
							</Notes>
							<Description>
								<RichText content={scent.description}/>
							</Description>
							<Detail>
								<RichText content={scent.detail}/>
							</Detail>
							<Listen>
								<Button link={{url: scent.playlistLink, blank: 'blank'}} noArrow className='h3'>Listen to the playlist<PlayIcon /></Button>
							</Listen>
						</Scent>
					)}
				</Inner>
				<ExploreMobile>
					<Button className='h3' link={content?.button?.link}>{content?.button?.text}</Button>
				</ExploreMobile>
			</Content>
		</Wrap>
	)
}

const Wrap = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	border-bottom: 1px solid var(--black);
	${mobile}{
		grid-template-columns: 1fr;
		position: relative;
	}
`
const Images = styled.div`
	position: relative;
	grid-column: 1/2;
	grid-row: 1;
	${mobile}{
		position: sticky;
    top: 0;
		background: var(--beige);
		z-index: 1;
		border-bottom: 1px solid var(--black);
		grid-column: span 1;
	}
`
const StickyImage = styled(Image)`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
`
const StickyContainer = styled.div`
	position: sticky;
	top: 55px;
	height: calc(100vh - 55px);
	${mobile}{
		position: relative;
		max-height: 50vh;
		top: 0;
	}
`
const ContentRight = styled.div`
	position: relative;
	grid-column: 2/3;
	grid-row: 1;
	pointer-events: none;
	${mobile}{
		display: none;
	}
`
const StickyContainerRight = styled(StickyContainer)`
	z-index: 1;
	top: 0px;
	bottom: 0;
	height: calc(100vh + 55px);
`
const StickyTitle = styled.h2`
	text-align: center;
	padding-top: var(--xxxxl);
	padding-bottom: var(--s);
	background-color: var(--beige);
`
const Explore = styled.div`
	text-align: center;
	position: absolute;
	bottom: 0px;
	left: 0;
	right: 0;
	padding-bottom: var(--xxxxl);
	background-color: var(--beige);
	padding-top: var(--s);
	pointer-events: all;
	${mobile}{
		padding-bottom: 0px;
		position: relative;
	}
`
const Content = styled.div`
	grid-column: 2/3;
	grid-row: 1;
	text-align: center;
	padding: var(--xxxxl) var(--m);
	${mobile}{
		grid-column: span 1;
		grid-row: 2;
	}
`
const Inner = styled.div`
	max-width: 480px; 
	margin: 0 auto;
`
const Title = styled.h2`
	margin-bottom: var(--l);
`
const Text = styled.div`
	margin-bottom: var(--s);
`
const Scroll = styled.div`
	margin-bottom: var(--xxxxl);
`
const Darr = styled(Rarr)`
	transform: rotate(90deg);
	margin-left: var(--xxs);
	position: relative;
	top: 1px;
	width: 13px; 
	height: 13px;
`
const Scent = styled.div`
	margin-bottom: var(--xxxl);
	${mobile}{
		margin-bottom: var(--xxxxl);
		&:last-of-type{
		margin-bottom: var(--xxxl);
	}
	}
`
const ScentTitle = styled.h2`
	margin-bottom: var(--l);
`
const Number = styled.span`
	display: inline-block;
	margin-right: var(--xs);
`
const Notes = styled.div`
	margin-bottom: var(--m);
`
const Description = styled.h3`
	margin-bottom: var(--xl);
`
const Detail = styled.div`
margin-bottom: var(--m);
`
const Listen = styled.button`
`
const PlayIcon = styled(Play)`
	margin-left: var(--xxs);
	width: 13px; 
	height: 13px;
`
const ExploreMobile = styled.div`
	display: none;
	text-align: center;
	padding-top: var(--s);
	${mobile}{
		display: block;
	}
`
ScrollJack.propTypes = {
	className: PropTypes.string,
	content: PropTypes.object,
}

export default ScrollJack